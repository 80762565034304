import React from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar, NavbarBrand, Button,
  NavItem,
  NavLink,
  Nav
} from 'reactstrap';

export default function Navigation({ isLoggedIn, userRole, handleLogout }) {
  if (isLoggedIn) {
    if (userRole.role === 'admin') {
      return <AdminNav handleLogout={handleLogout} />;
    } else {
      return <UserNav handleLogout={handleLogout} />;
    }
  } else {
    return <AnonymousNav />
  }
}

function AnonymousNav() {
  return (
    <Navbar>
      <NavbarBrand>
        Time Clock
      </NavbarBrand>
    </Navbar>
  )
}

function UserNav({ handleLogout }) {
  return (
    <Navbar>
      <NavbarBrand>
        Time Clock
      </NavbarBrand>
      <Nav className="mr-auto">
        <NavItem>
          <NavLink tag={Link} to="/">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/dashboard/weekly">Reports</NavLink>
        </NavItem>
      </Nav>
      <Button onClick={handleLogout}>Log Out</Button>
    </Navbar>
  )
}

function AdminNav({ handleLogout }) {
  return (
    <Navbar>
      <NavbarBrand>
        Time Clock
      </NavbarBrand>
      <Nav className="mr-auto">
        <NavItem>
          <NavLink tag={Link} to="/">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/dashboard/weekly">Reports</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/admin">Admin</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/admin/notifications">
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bell-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
            </svg>
          </NavLink>
        </NavItem>
      </Nav>
      <Button onClick={handleLogout}>Log Out</Button>
    </Navbar>
  )
}
